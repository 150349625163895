import {useState, useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './App.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position:  'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function App() {
  const [refresh, setRefresh] = useState(true);
  const [modal, setModal] = useState(true);
  const [numeJucator, setNumeJucator] = useState(''); 
  const [sesiune, setSesiune] = useState('');
  const [sesiuni, setSesiuni] = useState([]);

  const [simbol, setSimbol] = useState('');
  const [disabled, setDisabled] = useState(false);

  const [linia1, setLinia1] = useState({'0':'', '1':'', '2':''});
  const [linia2, setLinia2] = useState({'0':'', '1':'', '2':''});
  const [linia3, setLinia3] = useState({'0':'', '1':'', '2':''});


  function handleClick(linia, coloana) {
    if(linia === 1){
      linia1[coloana] = simbol;
    }else if(linia === 2){
      linia2[coloana] = simbol;
    }else{
      linia3[coloana] = simbol;
    }
    axios.post('https://x-stefan-0.gddservices.ro/updateData', {
    Simbol:simbol,
    Jucator: numeJucator,
    linia1: linia1,
    linia2: linia2,
    linia3: linia3,
    Sesiune: sesiune.SesiuneID,
  })
  .then(function (response) {
    setSesiune(response.data.recordset[0])
    linia1['0'] = response.data.recordset[0].p1;
    linia1['1'] = response.data.recordset[0].p2;
    linia1['2'] = response.data.recordset[0].p3;

    linia2['0'] = response.data.recordset[0].p4;
    linia2['1'] = response.data.recordset[0].p5;
    linia2['2'] = response.data.recordset[0].p6;

    linia3['0'] = response.data.recordset[0].p7;
    linia3['1'] = response.data.recordset[0].p8;
    linia3['2'] = response.data.recordset[0].p9;

  })
  .catch(function (error) {
    console.log(error);
  });
}

    function sesiuneNoua(){
      axios.post('https://x-stefan-0.gddservices.ro/nouasesiune', {
        nume: numeJucator,
      })
      .then(function (response) {
        setModal(false)
        setSimbol('X')
        setSesiune(response.data.recordset[0])
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    function seteazaSesiunea(Jucator, Sesiune){
      axios.post('https://x-stefan-0.gddservices.ro/intraInSesiune', {
        Jucator1: Jucator,
        Jucator2: numeJucator,
        Sesiune: Sesiune,
      })
      .then(function (response) {
        setModal(false)
        setSimbol("0")
        setDisabled(true)
        console.log(response)
        setSesiune(response.data.recordset[0])
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    useEffect(() => {
              const interval = setInterval(() => {
            if(sesiune.length !== 0){
              axios.post('https://x-stefan-0.gddservices.ro/inSesiune', {
                Jucator: numeJucator,
                linia1: linia1,
                linia2: linia2,
                linia3: linia3,
                Sesiune: sesiune.SesiuneID,
              })
              .then(function (response) {
                console.log(response)
                setSesiune(response.data.recordset[0])
                linia1['0'] = response.data.recordset[0].p1;
                linia1['1'] = response.data.recordset[0].p2;
                linia1['2'] = response.data.recordset[0].p3;

                linia2['0'] = response.data.recordset[0].p4;
                linia2['1'] = response.data.recordset[0].p5;
                linia2['2'] = response.data.recordset[0].p6;

                linia3['0'] = response.data.recordset[0].p7;
                linia3['1'] = response.data.recordset[0].p8;
                linia3['2'] = response.data.recordset[0].p9;

                if(response.data.recordset[0].ultimul === simbol){
                  setDisabled(true)
                }else{
                  setDisabled(false)
                }

              })
              .catch(function (error) {
                console.log(error);
              });

       
            }else{
              axios.get('https://x-stefan-0.gddservices.ro/sesiuni')
              .then(function (response) {
                // handle success
                setSesiuni(response.data.recordset)
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              })       
            }
          },1000)
            return () => clearInterval(interval);

      });
  return (

    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />

      <Box sx={{ flexGrow: 1}}>
        <Grid container >
          <Grid item xs={12} md={6}>
            <Grid container >
              <Grid item xs={4} >
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() => handleClick(1,0)}>{linia1['0']}</Button>
              </Grid>
              <Grid item xs={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(1,1)}>{linia1['1']}</Button>
              </Grid>
              <Grid item xs={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(1,2)}>{linia1['2']}</Button>
              </Grid>

              <Grid item xs={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(2,0)}>{linia2['0']}</Button>
              </Grid>
              <Grid item xs={4} >
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(2,1)}>{linia2['1']}</Button>
              </Grid>
              <Grid item xs={4} >
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(2,2)}>{linia2['2']}</Button>
              </Grid>

              <Grid item xs={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(3,0)}>{linia3['0']}</Button>
              </Grid>
              <Grid item xs={4} md={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(3,1)}>{linia3['1']}</Button>
              </Grid>
              <Grid item xs={4} md={4}>
                <Button disabled={disabled} style={{height:'100px', fontSize:'100px', border: '1px solid'}} fullWidth onClick={() =>handleClick(3,2)}>{linia3['2']}</Button>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container row>
              <Grid item xs={8} >
                <h1>Sesiuni</h1>
              </Grid>
              <Grid item xs={4} >
                <Button  onClick={() =>handleClick(3.2)}>Refresh</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>


      <div>
        <Modal
          open={modal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{textAlign: 'center'}} sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Bine ai venit la cel mai smecher joc
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              de X si 0
            </Typography>
            <TextField   value={numeJucator}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNumeJucator(event.target.value);
              }}
              id="standard-basic" label="Introduce Numele" variant="standard" />

            <Typography id="modal-modal-title" variant="h6" component="h2">
              Sesiuni disponibile
            </Typography>

          <Box style={{ textAlign: 'center',  width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24}} >
              {sesiuni.length !== 0
                ?<>
                  <Grid row container>
                    {sesiuni.map(({ Jucator1, SesiuneID }, index) => <Grid item xs={12}> <Button variant="standart" onClick={() =>seteazaSesiunea(Jucator1, SesiuneID)}>{SesiuneID}- Joaca cu {Jucator1}</Button> </Grid>)}
                  </Grid>
                </>
                :null
              }
          </Box>

            <Button variant="contained" onClick={() =>sesiuneNoua()}>Deschide sesiune noua</Button>
  
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default App;
